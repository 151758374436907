import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useCurrentStep, useOrderEntryBasket, useSelectCustomer, useSelectLang, useSelectPartnerCustomer, useSelectPartners, useSelectUser, useStep1Data, useStep2Data, useStep3Data } from "../../redux/store";
import { accountNeeded, useTranslations } from "../../utils/helper/utils";
import { TRANSLATIONS } from "../../constants/transitions/uiTranslations";
import { NfoCustomerLocationsStep } from "../../components/partner/newCustomer/steps/locations.step";
import { NfoCustomerProductSelectionStep } from "../../components/partner/newCustomer/steps/productSelection.step";
import { NfoCustomerConfigureProductStep } from "../../components/partner/newCustomer/steps/configureProduct.step";
import { NfoCustomerConfigureTelephoneConnectionsStep } from "../../components/partner/newCustomer/steps/configureTelephoneConnections.step";
import { NfoCustomerContractDocumentsStep } from "../../components/partner/newCustomer/steps/contractDocuments.step";
import { NfoCustomerOrderOverviewStep } from "../../components/partner/newCustomer/steps/orderOverview.step";
import { IAccounts, IAddresses, IPartnerCustomer } from "../../constants/types/types.constants";
import { apiCallAction } from "../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../constants/action.constants";
import { selectCurrentLanguage } from "../../../core/uiLanguage/lang.slice";
import { Address, AddressPortal, Basket } from "compax-api";
import { translateAddressToPortalAddress, translateCustomerToCustomerPortal } from "../../../core/utils";
import { saveStep1Data, saveStep2Data } from "../../redux/actions/step.action";
import { NfoStepperComponent } from "../../components/partner/newCustomer/stepper/stepper.component";
import cs from "classnames";
import commonStyles from '../../sass/base/common.module.scss';
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { NfoStepperMiniBasket } from "../../components/partner/newCustomer/miniBasket/miniBasket.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;
export const MainContractOrderPage = () => {
  const translations = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const dispatch = useDispatch();
  const dis: AppDispatch = useDispatch();
  const currentStep = useCurrentStep();
  const basket: Basket = useOrderEntryBasket();
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const theCustomer = useSelectCustomer() as any;
  const currentLanguage = selectCurrentLanguage(useSelectLang());
  const siteAddresses: AddressPortal[] = [];
  const step1Data = useStep1Data();
  const step2Data = useStep2Data();
  const user = useSelectUser();
  const [orderEntryReady, setOrderEntryReady] = useState(false);
  const step3Data = useStep3Data();
  const partners = useSelectPartners();


  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_COUNTRY));
    dis(apiCallAction(ACTION_CONST.API_GET_ADDRESSES,
      { language: currentLanguage, selectedPartnerCustomerId: selectedPartnerCustomer?.id })).then((result:any) => {
        prepare(result).then(() => {
          setOrderEntryReady(true)
        });
      }
    );
  }, []);

  const isOrderEntryNotReady = () => {
    return step2Data?.siteAddresses.length === 0 || step1Data?.customer === undefined || step1Data?.mainAddress === undefined;
  }


  const createBasket = (mainCountryId: number) => {
    dis(apiCallAction(selectedPartnerCustomer.id ? ACTION_CONST.API_GET_PARTNER_CUSTOMERS_ACCOUNTS : ACTION_CONST.API_GET_ACCOUNTS,
      { customerId: selectedPartnerCustomer.id })).then((accounts: IAccounts) => {
      dis(apiCallAction(ACTION_CONST.API_BASKET_CREATE_ORDER_ENTRY, { customerId: selectedPartnerCustomer.id ? selectedPartnerCustomer.id : user?.customer }, true, undefined, true)).then((basket) => {
          dispatch(apiCallAction(ACTION_CONST.API_BASKET_PREPARE_ORDER_ENTRY, {
            basketId: basket.id,
            addressCountryId: !accountNeeded(partners[0]) ? mainCountryId : (accounts?.reduce((curr, prev) => (curr > prev) ? curr : prev).billToAddress?.country.id || mainCountryId)
          }))
        }
      )
    })
  }


  const prepare = (sites: IAddresses) => {
    return new Promise<any>((resolve, reject) => {

      if (isOrderEntryNotReady()) {
        let translatedCustomer =
          translateCustomerToCustomerPortal(
            selectedPartnerCustomer?.id ? selectedPartnerCustomer : theCustomer);

        sites?.map((site: Address) => {
          let addressPortal = translateAddressToPortalAddress(site);
          if (addressPortal.addressType?.id == 102) {
            dis(saveStep1Data({ ...step1Data, customer: translatedCustomer, mainAddress: addressPortal }));
            createBasket(addressPortal.country.id);
            if(step1Data?.migrationCustomerId){
              // filter the mainAddress if eq
              let migrateSiteAddresses = step2Data?.siteAddresses.filter((siteAddress: AddressPortal) => {
                return !(+addressPortal.country.id === siteAddress.country.id
                  && addressPortal.zip === siteAddress.zip
                  && addressPortal.city === siteAddress.city
                  && addressPortal.street === siteAddress.street
                  && addressPortal.houseNumber === siteAddress.houseNumber
                  && addressPortal.companyName1 === siteAddress.companyName1
                  && addressPortal.companyName2 === siteAddress.companyName2);
              });
              dispatch(saveStep2Data({ siteAddresses: [...siteAddresses, ...migrateSiteAddresses], formdata: {} }));
            }else{
              dispatch(saveStep2Data({ siteAddresses: siteAddresses, formdata: {} }));
            }
          }

        });


      }
      resolve("done");
    });
  }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const stepTitles = [
    { stepNumber: 4, stepTitle: translations.locations() },
    { stepNumber: 5, stepTitle: translations.productSelection([6,7].includes(currentStep) ? ' (' + (step3Data?.activeProduct?.displayValue + ' - ' + (1 + Object.values(basket.entries).filter(entry => entry.product.id === step3Data?.activeProduct?.id).findIndex(entry => entry.uuid === step3Data?.activeEntryUuid))) + ')' : '') },
    { stepNumber: 6, stepTitle: translations.productConfiguration() },
    { stepNumber: 7, stepTitle: translations.telephoneConnectionConfiguration() },
    { stepNumber: 8, stepTitle: translations.documents() },
    { stepNumber: 9, stepTitle: translations.orderSummary() }
  ];


  const renderStep = () => {
    switch (currentStep) {
      case 4:
        return <NfoCustomerLocationsStep/>;
      case 5:
        return <NfoCustomerProductSelectionStep/>;
      case 6:
        return <NfoCustomerConfigureProductStep/>;
      case 7:
        return <NfoCustomerConfigureTelephoneConnectionsStep/>;
      case 8:
        return <NfoCustomerContractDocumentsStep/>;
      case 9:
        return <NfoCustomerOrderOverviewStep/>;
      default:
        return <NfoCustomerLocationsStep/>;
    }
  }

  return (
    <>
      <div className={cs(commonStyles.columnContainer)}>
        <div className={commonStyles.column}>
          {orderEntryReady && renderStep()}
        </div>
        <div className={cs(commonStyles.column)}>
          <NfoStepperComponent active={currentStep} steps={stepTitles}/>
          {currentStep !== 4 && currentStep !== 8 && currentStep !== 9 && <NfoStepperMiniBasket tmpBasket={basket}/>}
        </div>
      </div>
    </>
  )
};
