import { useTheme } from "../../../../../../core/utility/themeContext";
import { useDispatch } from "react-redux";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import { useSelectCarrier, useSelectCountries, useSelectedMainContract, useSelectError, useSelectLang, useSelectNumberOrder, useSelectUserLegalEntity } from "../../../../../redux/store";
import { IDocument, SelectOption } from "../../../../../constants/types/types.constants";
import React, { useEffect, useState } from "react";
import { apiCallAction } from "../../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../../constants/action.constants";
import { Address, LnpPhoneNumberBlock, Phone, TelcoData } from "compax-api";
import { currency } from "../../../../../../core/utils";
import { CLIENTS_IDS, COUNTRY_IDS, ID_SALUTATIONS, INPUT_MAX_LENGTHS } from "../../../../../constants/configs/config.constants";
import { CpxModal } from "../../../../../../core/components/modal.component";
import cs from "classnames";
import { CpxDropdownWithLabel } from "../../../../../../core/components/dropdown.component";
import { CpxInputWithLabel } from "../../../../../../core/components/inputWithLabel.component";
import { CpxSwitch } from "../../../../../../core/components/switch.component";
import { PortingNumberSwitch } from "../../../../../components/partner/newCustomer/configureProduct/productOptionModal/portingFrom/portingNumberSwitch";
import { Alert } from "../../../../../components/common/Alert/Alert.component";
import { selectCurrentLanguage } from "../../../../../../core/uiLanguage/lang.slice";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useFormik } from "formik";
import { createValidatorSchema, createValidatorSchemaArray, MaxLengthCheck, numberCheck, requiredCheck } from "../../../../../utils/validation";
import { clearError } from "../../../../../redux/actions/error.action";
import { OrderPortingDisplayAddressForm } from "./orderPortingDisplayAddressForm";
import { CpxFormikForm } from "../../../../../../core/components/formikForm.component";
import { deleteOCNStepData, saveOCNStepData } from "../../../../../redux/actions/numberOrder.action";
import { NumberPortingDocumentFormComponent } from "../../../../../components/customer/numbers/numberPortingDocumentForm/numberPortingDocumentForm.component";
import { NfoActivationDate } from "../../../../../components/partner/newCustomer/configureProduct/activationDate/activationDate.component";


type AppDispatch = ThunkDispatch<any, any, AnyAction>;

type CallNumberConfiguration = {
  callNumber: string;
}

export const NfoOrderPortingPageTwo = ({
                                         onConfirm,
                                         backToPreviousPage,
                                         cancelModal,
                                         customerId
                                       }: any) => {

  const internalClassName = 'porting-numbers-modal';
  const dispatchTemp: AppDispatch = useDispatch();
  const dispatch: AppDispatch = useDispatch();
  const currentLang = selectCurrentLanguage(useSelectLang());
  const theme = useTheme();
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translations = useTranslations(TRANSLATIONS.orderCallNumberModal);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const translationsPortingNumbers = useTranslations(TRANSLATIONS.portingNumbersModal);
  const currentProviders = useSelectCarrier();
  const ErrorsOfBE = useSelectError();
  const client = useSelectUserLegalEntity();
  const countries = useSelectCountries();
  const selectedMainContract = useSelectedMainContract();

  const numberOrder: any = useSelectNumberOrder();
  const NO_OPTION_SELECTED: number = -1;
  const DEFAULT_SELECT_OPTION: SelectOption = {
    id: NO_OPTION_SELECTED,
    name: translationsCommon.defaultSelect(),
  }
  const [selectedLegalEntity, setSelectedLegalEntity] = useState(
    numberOrder?.stepData?.selectedLegalEntity || DEFAULT_SELECT_OPTION);
  const [selectableAddresses, setSelectableAddresses] = useState([DEFAULT_SELECT_OPTION]);
  const [selectedAddress, setSelectedAddress] = useState(
    numberOrder?.stepData?.selectedAddress || DEFAULT_SELECT_OPTION);
  const [oldCustomerNr, setOldCustomerNr] = useState(numberOrder?.stepData?.oldCustomerNr || "");
  const [selectedProvider, setSelectedProvider] = useState(
      numberOrder?.stepData?.selectedProvider || DEFAULT_SELECT_OPTION);
  const [selectedProviderNonDe, setSelectedProviderNonDe] = useState(
      numberOrder?.stepData?.selectedProviderNonDe || '');
  const [isRecourse, setIsRecourse] = useState(numberOrder?.stepData?.isRecourse || false);
  const legalEntityOptions: Array<SelectOption> = numberOrder.customerLegalEntities?.map((legalEntity: any, index: any) => {
    return {
      id: index,
      name: `${legalEntity.countryCodePhone} - ${legalEntity.country?.description}`,
      data: legalEntity,
    };
  });
  const [selectedProduct, setSelectedProduct] = useState(DEFAULT_SELECT_OPTION)

  const [currentDocument, setCurrentDocument] = useState<IDocument>(numberOrder?.stepData?.document || { label: "", fileName: null, documentTypeId: 7, documentId: null, type: null, uploaded: false, entries: []});
  const [activationDateErrors, setActivationDateErrors] = useState(false);

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_CARRIER));
  }, [currentLang]);

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_COUNTRY));
  }, [currentLang]);

  const [isConfirmed, setConfirmation] = useState(numberOrder?.stepData?.isConfirmed || false);


  const backToProductTypeSelection = () => {
    backToPreviousPage();
  };

  const cancelModalHandler = () => {
    handleDeleteDocument()
    dispatch(deleteOCNStepData())
    cancelModal(false);
  }

  const handleAddressSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selectedId: number = +e.target.value;
    let selectedAddressOption = selectableAddresses.find(address => address.id === selectedId) || DEFAULT_SELECT_OPTION;
    setSelectedAddress(selectedAddressOption);
    formik.setFieldValue('selectedConnectionAddress.id', selectedId)
    /*setSelectedAreaCode(DEFAULT_SELECT_OPTION);
    setAreaCodeInput('');
    setSelectedProduct(DEFAULT_SELECT_OPTION);
    setErrorMsg('');*/
  }

  const onChangeCancelConnection = () => {
    formik.setFieldValue('isCancelConnection', !isConfirmed);
    setConfirmation(!isConfirmed);
  }

  const onChangeSelectedCurrentProvider = (e: any) => {
    formik.handleChange(e);
    let selectedId: number = +e.target.value;
    let selectedProviderOption = currentProvidersOptions.find(provider => provider.id === selectedId) || DEFAULT_SELECT_OPTION;
    setSelectedProvider(selectedProviderOption);
  }

  const onChangeSelectedCurrentProviderNonDe = (e: any) => {
    formik.handleChange(e);
    setSelectedProviderNonDe(e.target.value);
  }

  /**
   * Get Legal Entities and corresponding data via API
   */
  useEffect(() => {
    dispatch(
      apiCallAction(ACTION_CONST.API_OCN_GET_CUSTOMER_LEGAL_ENTITIES, { contractId: selectedMainContract?.contractId, selectedPartnerCustomerId: customerId })
    );
  }, [dispatch]);


  const currentProvidersOptions = Array.isArray(currentProviders) ? currentProviders.map((currentProvider) => {
    return {
      id: currentProvider.id,
      name: currentProvider.description,
      data: currentProvider,
    }
  }) : [];

  const handleAreaCodeSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selectedId: number = +e.target.value;
    /*let selectedAreaCodeOption = selectableAreaCodes.find(areaCode => areaCode.id === selectedId) || DEFAULT_SELECT_OPTION;
    setSelectedAreaCode(selectedAreaCodeOption);
    setSelectedProduct(DEFAULT_SELECT_OPTION);
    setErrorMsg('');*/
  }

  const handleAreaCodeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    /*setAreaCodeInput(e.target.value);
    setErrorMsg('');*/
  }

  const handleOldCustomerNumber = (e: any) => {
    setOldCustomerNr(e.target.value);
    formik.setFieldValue('oldCustomerNr', e.target.value);
  }

  const onChangeResourceCheckBox = () => {
    formik.setFieldValue('isResourceAcquisition', !isRecourse);
    setIsRecourse(!isRecourse);
  }

  const handleLegalEntitySelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selectedId: number = +e.target.value;
    let selectedLegalEntityOption = legalEntityOptions.find(le => le.id === selectedId) || DEFAULT_SELECT_OPTION;
    setSelectedLegalEntity(selectedLegalEntityOption);
    setSelectedAddress(DEFAULT_SELECT_OPTION);
    formik.setFieldValue('connection.id', selectedId);
    /*setSelectedAreaCode(DEFAULT_SELECT_OPTION);
    setAreaCodeInput('');
    setSelectedProduct(DEFAULT_SELECT_OPTION);
    setErrorMsg('');*/
  }

  /**
   * Prepare order after a legal entity got selected
   */
  useEffect(() => {
    if (selectedLegalEntity?.id !== NO_OPTION_SELECTED) {
      dispatch(
        apiCallAction(ACTION_CONST.API_OCN_GET_PREPARE_ORDER, {
          ...selectedLegalEntity.data.salesOrganisation,
          selectedPartnerCustomerId: customerId,
          telePhoneLineContractId: selectedLegalEntity.data.telephoneLineContractId,
          telePhoneLineServiceId: selectedLegalEntity.data.telephoneLineServiceId
        })
      );
    }
  }, [selectedLegalEntity]);

  /**
   * Preparing data for selectable addresses
   */
  useEffect(() => {
    if (numberOrder.customerLegalEntities?.length > 0) {
      let addressOptions: Array<SelectOption> = [];
      let possibleAddresses: Array<Address> = numberOrder.customerLegalEntities[0].possibleAddresses!;
      if (possibleAddresses.length > 0) {
        addressOptions = possibleAddresses.map(address => {
          return {
            id: address.id!,
            name: `${address.street}${address.houseNumber !== undefined ? ' ' + address.houseNumber: ''}${address.houseNumberSuffix || ""}, ${address.zip} ${address.city} (${address.role?.description})`,
            data: address,
          };
        });
        setSelectableAddresses(addressOptions);
      } else {
        setSelectableAddresses([DEFAULT_SELECT_OPTION]);
      }
    }
  }, [selectedLegalEntity]);

  /**
   * Prepare Products for selection
   */
  useEffect(() => {
    if (numberOrder.possiblePortingServices?.length > 0) {
      let productOptions: Array<SelectOption> = numberOrder.possiblePortingServices.map((service: any, index: any) => {
        let priceString = "";
        if (typeof service.product.charges !== "undefined"
          && service.product.charges.length > 0
          && typeof service.product.charges[0].amountNet !== "undefined"
          && service.product.charges[0].amountNet > 0) {
          priceString = `(${currency(service.product.charges[0].amountNet, service.product.charges[0]?.currency?.description, true)}*)`;
        }
        return {
          id: index,
          name: `${service.product.displayValue!} ${priceString}`,
          data: service,
        }
      });
      setSelectedProduct(productOptions[0]);
    } else {
      setSelectedProduct(DEFAULT_SELECT_OPTION);
    }
  }, [numberOrder.possiblePortingServices]);


  const getCountryById = (id: number) => {
    return countries?.filter((country: any) => country.id === id)[0]?.description;
  }
  const requiredMsg = translation.fieldRequired();

  const [selectedCallNumberType, setSelectedCallNumberType] = useState(numberOrder?.stepData?.selectedCallNumberType || { multipleSubscriberNumbers: client.id !== CLIENTS_IDS.AUT, numberBlock: client.id === CLIENTS_IDS.AUT });

  /* Multiple Subscriber Numbers (MSN) States */
  const [allNumbersIsChecked, setAllNumbersIsChecked] = useState(numberOrder?.stepData?.allNumbersIsChecked || false);
  const [shortenOnConsole, setShortenOnConsole] = useState(numberOrder?.stepData?.shortenOnConsole || false);
  const [callNumberConfigurations, setCallNumberConfigurations] = useState<CallNumberConfiguration[]>([{ callNumber: '' }]);

  const createPortingNumberList = (portingNumbers: any, localAreaCode: string): Array<Phone> => {

    const phones: Array<Phone> = new Array<Phone>();
    portingNumbers?.map((number: any) => {
      let thePhone: Phone = {
        phoneType: { id: 2 },
        areaCode: localAreaCode,
        cli: number.callNumber
      };
      phones.push(thePhone);
    })


    return phones;
  }

  const createAddress = (selectedAddress: any) => {

    let company = selectedAddress.salutation?.id && (parseInt(String(selectedAddress.salutation.id)) === ID_SALUTATIONS.COMPANY);

    let theAddress: any = {
      salutation: selectedAddress.salutation,
      companyName: company ? selectedAddress.companyName : null,
      firstName: !company ? selectedAddress.companyName : null,
      lastName: !company ? selectedAddress.companyName2 : null,
      country: selectedAddress.country,
      street: selectedAddress.street,
      houseNumber: selectedAddress.houseNumber,
      zip: selectedAddress.zip,
      city: selectedAddress.city,
      city2: selectedAddress.city2,
      city3: selectedAddress.city3,
      district: selectedAddress.district ? selectedAddress.district : selectedAddress.province ? selectedAddress.province : selectedAddress.county,
      buildingPart: selectedAddress.additionalAddress
    };

    return theAddress;
  }

  const createPhoneNumberBlock = (numberBlock: any): LnpPhoneNumberBlock => {

    const blockTo = Number(numberBlock.to);
    const blockFrom = Number(numberBlock.from);
    const result: any = blockTo - blockFrom + 1;

    return {
      blockFrom: numberBlock.from,
      blockTo: numberBlock.to,
      blockSize: result,
      extensionNumber: numberBlock.extensionNumber,
      consoleNumber: numberBlock.console,
      areaCode: numberBlock.localAreaCode,
      shortenOnConsole: numberBlock.shortenOnConsole

    };
  }

  function calculateFutureDate(numDays: number) {
    const currentDate = new Date();
    let count = 0;

    while (count < numDays) {
      currentDate.setDate(currentDate.getDate() + 1);
      const dayOfWeek = currentDate.getDay();

      // Check if the current day is a weekend (Saturday or Sunday)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
    }

    return currentDate;
  }




  const createPortingObject = (values: any, singlePorting: boolean): TelcoData => {


    const telcoData: TelcoData = {};

    const BUSINESS_DAYS = 20;

    telcoData.lnpData = {
      currentCarrierNonDe: (client.id !== CLIENTS_IDS.NFON && client.id !== CLIENTS_IDS.DTS) ? values.currentCarrierNonDe : undefined,
      currentCarrier: (client.id === CLIENTS_IDS.NFON || client.id === CLIENTS_IDS.DTS) ? { id: +values.currentProvider.id } : undefined,
      portPhoneNumbers: singlePorting ? createPortingNumberList(
        values.multipleSubscriberNumbers.callNumberConfigurations,
        values.multipleSubscriberNumbers.localAreaCode) : undefined,
      contractTermination: values.isCancelConnection,
      portAllNumbers: values.multipleSubscriberNumbers.portAllNumbers,
      resourceTransfer: values.isResourceAcquisition,
      installationAddress: createAddress(selectedAddress.data),
      portPhoneNumberBlock: !singlePorting ? createPhoneNumberBlock(values.numberBlock) : undefined,
      referenceNumberPartner: values.oldCustomerNr,
      scheduledDate: values.scheduledDate,
      dateMode: values.scheduledDate.split('T')[0] == calculateFutureDate(20).toISOString().split('T')[0] ? 1 : 3

    }

    telcoData.siteAddress = createAddress(selectedAddress.data);
    return telcoData;
  }

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      connection: {
        id: selectedLegalEntity.id
      },
      selectedConnectionAddress: {
        id: selectedAddress?.id
      },
      isCancelConnection: isConfirmed,
      currentProvider: {
        id: selectedProvider.id
      },
      currentCarrierNonDe: selectedProviderNonDe,
      oldCustomerNr: '',
      isResourceAcquisition: isRecourse,
      /* Call Numbers */
      multipleSubscriberNumbers: numberOrder?.stepData?.multipleSubscriberNumbers || {
        localAreaCode: '',
        portAllNumbers: allNumbersIsChecked,
        callNumberConfigurations: callNumberConfigurations.map((configuration: any) => configuration),
      },
      numberBlock: numberOrder?.stepData?.numberBlock || {
        localAreaCode: '',
        extensionNumber: '',
        console: '',
        from: client.id !== CLIENTS_IDS.AUT ? '' : '0',
        to: client.id !== CLIENTS_IDS.AUT ? '' : '99999',
        shortenOnConsole: shortenOnConsole
      },
      scheduledDate: numberOrder?.stepData?.scheduledDate
    },
    validationSchema: createValidatorSchema({
      currentCarrierNonDe: MaxLengthCheck(INPUT_MAX_LENGTHS.currentCarrierNonDe, (client.id !== CLIENTS_IDS.NFON && client.id !== CLIENTS_IDS.DTS) && requiredCheck(requiredMsg)),
      currentProvider: createValidatorSchema({
        id: (client.id === CLIENTS_IDS.NFON || client.id === CLIENTS_IDS.DTS) && numberCheck(requiredMsg, requiredMsg),
      }),
      selectedConnectionAddress: createValidatorSchema({
        id: numberCheck(requiredMsg, requiredMsg)
      }),
      connection: createValidatorSchema({
        id: numberCheck(requiredMsg, requiredMsg)
      }),
      oldCustomerNr: MaxLengthCheck(INPUT_MAX_LENGTHS.default, false),
      multipleSubscriberNumbers: createValidatorSchema({
        localAreaCode: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.areaCode, selectedCallNumberType.multipleSubscriberNumbers && numberCheck(requiredMsg, translation.invalidLocalAreaCodeValidation())),
        callNumberConfigurations: createValidatorSchemaArray({
          callNumber: MaxLengthCheck(INPUT_MAX_LENGTHS.callNumber, selectedCallNumberType.multipleSubscriberNumbers && numberCheck(requiredMsg, translation.invalidCallNumberValidation()))
        })
      }),
      numberBlock: createValidatorSchema({
        localAreaCode: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.areaCode, selectedCallNumberType.numberBlock && numberCheck(requiredMsg, translation.invalidLocalAreaCodeValidation())),
        extensionNumber: MaxLengthCheck(INPUT_MAX_LENGTHS.callNumber, selectedCallNumberType.numberBlock && numberCheck(requiredMsg, translation.extensionNumber())),
        console: MaxLengthCheck(INPUT_MAX_LENGTHS.console, selectedCallNumberType.numberBlock && numberCheck(requiredMsg, translation.console())),
        from: MaxLengthCheck(INPUT_MAX_LENGTHS.callNumber, selectedCallNumberType.numberBlock && numberCheck(requiredMsg, translation.from())),
        to: MaxLengthCheck(INPUT_MAX_LENGTHS.callNumber, selectedCallNumberType.numberBlock && numberCheck(requiredMsg, translation.to())),
      }),
      scheduledDate: !activationDateErrors
    }),
    onSubmit: async (values: any) => {
      confirmForm();
    }
  });

  const confirmForm = () => {
    {
      let values = formik.values;
      dispatch(clearError());

      if (+selectedAddress.data.country.id === COUNTRY_IDS.DE) {

        const localAreaCode: string = selectedCallNumberType.multipleSubscriberNumbers ? values.multipleSubscriberNumbers.localAreaCode : values.numberBlock.localAreaCode;

        dispatchTemp(apiCallAction(ACTION_CONST.API_VALIDATE_LOCAL_AREA_CODE, {
          countryId: COUNTRY_IDS.DE,
          onkz: localAreaCode,
          stepper: "PORTING_FORM"
        })).then(() => {
          addOptionToBasket(values);

        })
          .catch((error) => {
            console.log(error);
          })

      } else {
        addOptionToBasket(values);
      }
    }
  }

  const handleDeleteDocument = () => {
    currentDocument.documentId && dispatch(apiCallAction(ACTION_CONST.API_DOCUMENTS_DELETE_DOCUMENT, { documentId: currentDocument.documentId }));
    setCurrentDocument({ label: "", fileName: null, documentTypeId: 7, documentId: null, type: null, uploaded: false, entries: [] })
  }


  const addOptionToBasket = (values: any) => {
    const portingNumberObject: TelcoData = createPortingObject(values, selectedCallNumberType.multipleSubscriberNumbers);
    dispatchTemp(
        apiCallAction(ACTION_CONST.API_BASKET_PUT_PRODUCT_OPTIONS_TELCO_DATA_ORDER, {
              basketId: numberOrder.basket.id,
              uuid: selectedProduct.data.parentUuid,
              optionId: selectedProduct.data.product.id,
              expanded: true,
              updateBasket: true,
              telcoDataArray: [portingNumberObject],
            },
            true, null, false)
    ).then((res) => {
      dispatchTemp(apiCallAction(ACTION_CONST.API_BASKET_PUT_DOCUMENT,
        {
          basketId: numberOrder.basket?.id,
          id: currentDocument.documentId,
          documentName: currentDocument.fileName,
          documentType: { id: currentDocument.documentTypeId },
          entries: [res.entries[0].options.find((opt:any) => opt.product.id === selectedProduct.data.product.id).uuid]
        }
      )).then(() => {
        let block = portingNumberObject?.lnpData?.portPhoneNumberBlock;
        let countryCodePhone = selectedLegalEntity.data.countryCodePhone;
        let numbers = selectedCallNumberType.multipleSubscriberNumbers ?
          portingNumberObject?.lnpData?.portPhoneNumbers?.map(phone => countryCodePhone + ' ' + phone.areaCode + ' ' + phone.cli) :
          [countryCodePhone + ' ' + block?.areaCode + ' ' + block?.extensionNumber + ' ' + block?.blockFrom + '-' + block?.blockTo];
        onConfirm(selectedLegalEntity.name, selectedAddress.name, selectedProduct.data.product, numbers);
      });
    });

    dispatch(saveOCNStepData(
        {
          selectedLegalEntity,
          selectedAddress,
          selectedProduct,
          isRecourse,
          oldCustomerNr,
          isConfirmed,
          selectedProvider,
          selectedProviderNonDe,
          allNumbersIsChecked,
          selectedCallNumberType,
          shortenOnConsole,
          callNumberConfigurations,
          numberBlock: formik.values.numberBlock,
          multipleSubscriberNumbers: formik.values.multipleSubscriberNumbers,
          document: currentDocument,
          scheduledDate: formik.values.scheduledDate,
          dateMode: formik.values.scheduledDate.split('T')[0] == calculateFutureDate(20).toISOString().split('T')[0] ? 1 : 3
        }))
  }


  return (
    <CpxFormikForm handleSubmit={formik.handleSubmit}
                   initialValues={formik.initialValues}
                   id={'porting-numbers-modal-data-form'}
    >
      <div className={'configurationBox__wrapper'}>
        <CpxModal
            confirmText={translations.next()}
            onCancel={() => cancelModalHandler()}
            onBack={() => backToProductTypeSelection()}
            className={internalClassName}
            formId={'porting-numbers-modal-data-form'}
            size={'large'}
            disableButton={!currentDocument?.uploaded || Object.keys(formik.errors).length > 0}
        >

          <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
            {translations.titlePage2Porting()}
          </h3>
          <p className={cs('subheading', `subheading-le--${theme}`)}>
            {translations.subTextPage2()}
          </p>
          <CpxDropdownWithLabel
            id={`${internalClassName}-connection`}
            defaultOption={DEFAULT_SELECT_OPTION}
            name={`connection.id`}
            options={legalEntityOptions}
            value={selectedLegalEntity.id}
            error={formik.errors?.connection?.id}
            required={true}
            onChange={handleLegalEntitySelection}
          >
            {translations.telephoneConnection()}
          </CpxDropdownWithLabel>

          <CpxDropdownWithLabel
            id={`${internalClassName}-location`}
            name={`selectedConnectionAddress.id`}
            defaultOption={DEFAULT_SELECT_OPTION}
            options={selectableAddresses}
            value={selectedAddress.id}
            disabled={selectedLegalEntity.id === NO_OPTION_SELECTED}
            onChange={handleAddressSelection}
            error={formik.errors?.selectedConnectionAddress?.id}
            required={true}
          >
            {translationsPortingNumbers.sites()}
          </CpxDropdownWithLabel>
          <h4 className={`${internalClassName}-subheading ${internalClassName}-subheading-le--${theme}`}>{translationsPortingNumbers.connection()}</h4>
          <div className={`${internalClassName}-switch-container`}>
            <CpxSwitch
              id={'isCancelConnection'}
              name={'isCancelConnection'}
              className={"switch"}
              onChange={onChangeCancelConnection}
              checked={isConfirmed}
            />
            {translationsPortingNumbers.switchContent()}
          </div>

          <div className={'addressInputGroup-container'}>
            <div className={'addressInputGroup'}>
              <div className={'addressInputGroup'}>
                {(client.id === CLIENTS_IDS.NFON || client.id === CLIENTS_IDS.DTS) ? (<CpxDropdownWithLabel
                  id={'currentProvider.id'}
                  name={`currentProvider.id`}
                  required={true}
                  onChange={onChangeSelectedCurrentProvider}
                  options={currentProvidersOptions}
                  defaultOption={DEFAULT_SELECT_OPTION}
                  value={formik.values?.currentProvider?.id}
                  error={formik.errors?.currentProvider?.id}
                >
                  {translationsPortingNumbers.currentProvider()}
                </CpxDropdownWithLabel>) : (
                  <CpxInputWithLabel
                    id={'currentCarrierNonDe'}
                    type="text"
                    onChange={onChangeSelectedCurrentProviderNonDe}
                    value={formik.values?.currentCarrierNonDe}
                    error={formik.touched?.currentCarrierNonDe && formik.errors?.currentCarrierNonDe}
                    required
                  >
                    {translationsPortingNumbers.currentProvider()}
                  </CpxInputWithLabel>
                )}
                <CpxInputWithLabel
                  id={'oldCustomerNr'}
                  type="text"
                  onChange={handleOldCustomerNumber}
                  value={oldCustomerNr}
                >
                  {translationsPortingNumbers.customerNr()}
                </CpxInputWithLabel>
              </div>
            </div>
          </div>

          {selectedAddress.id !== NO_OPTION_SELECTED && (<div className={`${internalClassName}-section-container`}>
            <h4 className={`${internalClassName}-subheading ${internalClassName}-subheading-le--${theme}`}>{translationsPortingNumbers.addressHeadline()}</h4>
            <p className={cs('subheading', `subheading-le--${theme}`)}>{translationsPortingNumbers.connectionSubtitle()}</p>
            <OrderPortingDisplayAddressForm selectedLocation={selectedAddress.data} getCountryById={getCountryById}/>
          </div>)}
          <div className={`${internalClassName}-section-container callNumbers`}>
            <h4 className={`${internalClassName}-subheading ${internalClassName}-subheading-le--${theme}`}>{translationsPortingNumbers.callNumberTitle()}</h4>
            <PortingNumberSwitch
              values={formik.values}
              touched={formik.touched}
              errors={formik.errors}
              handleChange={formik.handleChange}
              allNumbersIsChecked={allNumbersIsChecked}
              setAllNumbersIsChecked={setAllNumbersIsChecked}
              shortenOnConsole={shortenOnConsole}
              setShortenOnConsole={setShortenOnConsole}
              selectedCallNumberType={selectedCallNumberType}
              setSelectedCallNumberType={setSelectedCallNumberType}
              client={client}
              formik={formik}
              callNumberConfigurations={callNumberConfigurations}
              setCallNumberConfigurations={setCallNumberConfigurations}
            />
          </div>
          <NfoActivationDate setActivationDate={(date: Date) => formik.values.scheduledDate = date} isPortingDate={true} isValid={setActivationDateErrors} portingDate={numberOrder?.stepData?.dateMode == 3 && numberOrder?.stepData?.scheduledDate}/>
          <NumberPortingDocumentFormComponent parentClassName={internalClassName} currentDocument={currentDocument} setCurrentDocument={setCurrentDocument} handleDeleteDocument={handleDeleteDocument}/>
          {ErrorsOfBE?.errorData && ErrorsOfBE?.errorData?.length > 0 &&
            ErrorsOfBE?.requestData?.currentData?.stepper === "PORTING_FORM" && <Alert errors={ErrorsOfBE?.errorData}/>}
        </CpxModal>
      </div>
    </CpxFormikForm>
  );
};