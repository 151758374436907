import { faker } from '@faker-js/faker';
import { CpxButton } from "../../../../../core/components/button.component";
import { useCurrentStep, useSelectFeatures, useSelectUserLegalEntity, useStep1Data, useStep2Data, useStepperBillingInformationSelection } from "../../../../redux/store";
import './generateFakeData.scss';
import { saveBillingInformationSelection, saveStep1Data, saveStep2Data } from "../../../../redux/actions/step.action";
import { useDispatch } from "react-redux";
import { CLIENTS_IDS, COUNTRY_IDS, FEATURES_FLAG, ID_ADDRESSES } from "../../../../constants/configs/config.constants";
import { useEffect } from "react";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";

type Props = {
  salutationOptions?: any;
  contactLanguageOptions?: any;
  countryOptions?: any;
  setSalutation?: Function;
  setBillingData?: Function;
}

export const NfoGenerateFakeDataComponent = ({ salutationOptions, countryOptions, setSalutation, contactLanguageOptions, ...props }: Props) => {
  const currentStep = useCurrentStep();
  const dataOfStep1 = useStep1Data();
  const dataOfStep2 = useStep2Data();
  const features = useSelectFeatures();
  const billingInformationSelection = useStepperBillingInformationSelection();
  const dispatch = useDispatch();
  const client = useSelectUserLegalEntity();

  const germanZips = ['01159', '01609', '03130', '06862', '92546', '80798', '75433', '60596', '55585', '48499'];

  const getZipByCountry = (countryId: number) => {
    switch (countryId) {
      case COUNTRY_IDS.DE:
        const number = faker.number.int(germanZips.length - 1);
        return germanZips[number]
      case COUNTRY_IDS.AT:
        return faker.helpers.fromRegExp('[1-9]{1}[0-9]{3}')
      case COUNTRY_IDS.GB:
        return faker.helpers.fromRegExp('[a-zA-Z]{1}[A-Za-z0-9_]{1,3} [0-9]{1}[a-zA-Z]{2}')
      case COUNTRY_IDS.IT:
      case COUNTRY_IDS.ES:
      case COUNTRY_IDS.FR:
      default:
        return faker.helpers.fromRegExp('[0-9]{5}')
    }
  }

  const getCountryByClient = () => {
    switch (client.id) {
      case CLIENTS_IDS.NFON:
      case CLIENTS_IDS.DTS:
        return COUNTRY_IDS.DE;
      case CLIENTS_IDS.AUT:
        return COUNTRY_IDS.AT;
      case CLIENTS_IDS.UK:
        return COUNTRY_IDS.GB;
      case CLIENTS_IDS.IT:
        return COUNTRY_IDS.IT;
      case CLIENTS_IDS.FR:
        return COUNTRY_IDS.FR;
      case CLIENTS_IDS.ES:
        return COUNTRY_IDS.ES;
      default:
        return COUNTRY_IDS.DE;
    }
  }

  function getMainAddress() {
    let countryId = getCountryByClient();
    let zip = getZipByCountry(countryId)
    return {
      country: {
        id: countryId,
      },
      street: faker.location.street(),
      houseNumber: faker.location.buildingNumber(),
      additionalAddress: faker.datatype.boolean() && faker.location.street(),
      zip: zip,
      county: faker.location.county(),
      city: faker.location.city(),
      province: faker.location.county(),
      addressType: {
        id: ID_ADDRESSES.MAIN,
      },
    };
  }

  function getSitesObj(addressType: number, generateEmail: boolean) {

    let countryId = getCountryByClient();
    let zip = getZipByCountry(countryId)

    return {
      country: {
        id: countryId,
      },
      street: faker.location.street(),
      houseNumber: faker.location.buildingNumber() ,
      additionalAddress: faker.datatype.boolean() && faker.location.street(),
      zip: zip,
      county: faker.location.county(),
      city: faker.location.city(),
      province: faker.location.county(),
      salutation: {
        id: salutationOptions && salutationOptions[faker.number.int(3)]?.id,
      },
      companyName1: faker.datatype.boolean() ? faker.company.name() : faker.person.firstName(),
      companyName2: faker.datatype.boolean() ? faker.company.name() : faker.person.lastName(),
      email: generateEmail && faker.internet.email(),
      addressType: {
        id: addressType,
      },
    };
  }

  const customerDataObj = {
    /* MainDataForm */
    customer: {
      legalForm: {
        id: faker.number.int({min: 1, max: 40}),
      },
      salutation: {
        id: salutationOptions && salutationOptions[faker.number.int(3)]?.id,
      },
      language: {
        id: client.id !== CLIENTS_IDS.DTS ? faker.number.int({min: 1, max: 6}) : faker.number.int({min: 1, max: 1}),
      },
      companyName1: faker.datatype.boolean() ? faker.company.name() : faker.person.firstName(),
      companyName2: faker.datatype.boolean() ? faker.company.name() : faker.person.lastName(),
      taxIdentificationNumber: faker.datatype.boolean() && faker.helpers.fromRegExp('DE[0-9]{7}'),
      email: faker.internet.email(),
      companyNumber: '',
      phone: {
        countryCode: '+49',
        areaCode: faker.helpers.fromRegExp('[0-9]{3}'),
        cli: faker.helpers.fromRegExp('[0-9]{5}'),
        phoneType: {
          id: 2
        },
      },
    },
    /* RegisteredAddressForm */
    mainAddress: getMainAddress(),
  };

  const contactPersons = [
    {
      salutation: {
        id: salutationOptions && salutationOptions[faker.number.int(3)]?.id,
      },
      firstName: faker.person.firstName(),
      lastName: faker.person.lastName(),
      email: faker.internet.email(),
      //login: faker.internet.email(),
      phone: {
        countryCode: '+49',
        areaCode: faker.helpers.fromRegExp('[0-9]{3}'),
        cli: faker.helpers.fromRegExp('[0-9]{5}'),
        phoneType: {
          id: 2
        },
      },
      mobile: {
        countryCode: '+49',
        areaCode: faker.helpers.fromRegExp('[0-9]{3}'),
        cli: faker.helpers.fromRegExp('[0-9]{5}'),
        phoneType: {
          id: 2
        },
      },
      language: {
        id: client.id !== CLIENTS_IDS.DTS ? faker.number.int({min: 1, max: 6}) : faker.number.int({min: 1, max: 1}),
      },
      role: {
        id: '134'
      }
    },
    {
      salutation: {
        id: salutationOptions && salutationOptions[faker.number.int(3)]?.id,
      },
      firstName: faker.person.firstName(),
      lastName: faker.person.lastName(),
      email: faker.internet.email(),
      phone: {
        countryCode: '+49',
        areaCode: faker.helpers.fromRegExp('[0-9]{3}'),
        cli: faker.helpers.fromRegExp('[0-9]{5}'),
        phoneType: {
          id: 2
        },
      },
      mobile: {
        countryCode: '+49',
        areaCode: faker.helpers.fromRegExp('[0-9]{3}'),
        cli: faker.helpers.fromRegExp('[0-9]{5}'),
        phoneType: {
          id: 2
        },
      },
      language: {
        id: client.id !== CLIENTS_IDS.DTS ? faker.number.int({min: 1, max: 6}) : faker.number.int({min: 1, max: 1}),
      },
      role: {
        id: '113'
      }
    }
  ];

  const account = {
    accountName: faker.finance.accountName(),
    iban: faker.finance.iban({ formatted: false, countryCode: 'DE' }),
    bic: faker.finance.bic(),
    paymentMode: {
      id: 1,
    },
    paymentMedia: {
      id: 4,
    },
    billAddress: getSitesObj(ID_ADDRESSES.BILLING, faker.datatype.boolean()),
  }



  const sitesObj = getSitesObj(ID_ADDRESSES.CONNECTION, false);

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_ORDER_ENTRY_GENERATOR }))
  }, [])

  const generateData = () => {
    switch (currentStep) {
      case 1:
        dispatch(saveStep1Data(customerDataObj));
        setSalutation && setSalutation(customerDataObj.customer.salutation.id)
        break;
      case 2:
        dispatch(saveStep1Data({...dataOfStep1, contactPersons}));
        break;
      case 3:
        dispatch(saveStep1Data({...dataOfStep1, account}));
        dispatch(saveBillingInformationSelection({ ...billingInformationSelection, isSEPA: true }))
        break;
      case 4:
        dispatch(saveStep2Data({...dataOfStep2, formData: sitesObj}));
        break;
      default:
        break;
    }
    if(props.setBillingData) {
      props.setBillingData(account);
    }
  }

  return (
      <>
        {features.PORTAL_ORDER_ENTRY_GENERATOR && !dataOfStep1?.migrationCustomerId && !dataOfStep1?.partnerVoNumber && (
          <div className={'generate-fake-data'}>
            <CpxButton
              onClick={() => generateData()}
              type='button'
            >
              Generate Fake Data
            </CpxButton>
          </div>
        )}
      </>
  )
}