import React, { useEffect, useState } from "react";
import { CpxPaginatedTable } from "../../../../../core/components/paginatedTable.component";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useGetSites, useTranslations } from "../../../../utils/helper/utils";
import { NfoSiteDetails } from "../sitesDetails/sitesDetails.component";
import "./sitesTable.scss";
import { Address } from "compax-api";
import cs from "classnames";
import { useTheme } from "../../../../../core/utility/themeContext";
import { NfoSitesHelpBox } from "../sitesHelpBox/sitesHelpBox.component";
import { ACTION_CONST } from "../../../../constants/action.constants";

export const NfoSitesTable = () => {
    const [active, setActive] = useState(undefined as string | undefined);
    const translations = useTranslations(TRANSLATIONS.sites);
    const internalClassName = "sitesTable";
    const theme = useTheme();
    const sites = useGetSites();
    const [data, setData] = useState(sites);

  useEffect(() => {
    setData(sites)
  }, [sites]);



    const sitesTableRowData = (address: Address) => ({
        id: "" + address.id,
        cellData: [
            <div className={cs("tableFirstChild", `tableFirstChild-font-le--${theme}`)}>
                <p>{address.companyName}</p>
                <p>
                    {address.firstName} {address.lastName}
                </p>
            </div>,
            `${address.street}${address.houseNumber !== undefined ? ' ' + address.houseNumber: ''}`,
            address.zip,
            address.city,
            address.country.description,
            address.role?.description,
            <NfoSitesHelpBox addressTypeId={address.role?.id}/>
        ],
    });

    return (
        <>
            {active && (
                <NfoSiteDetails id={active} onClose={() => setActive(undefined)}/>
            )}
            <article className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
                <CpxPaginatedTable
                    id="sitesTable"
                    tableHeader={[
                      { label: translations.tableHeadingName(), sort: true, sortBy: 'companyName', sortMethod: 'string' },
                      { label: translations.tableHeadingStreet(), sort: true, sortBy: 'street', sortMethod: 'string' },
                      { label: translations.tableHeadingPostalCode(), sort: true, sortBy: 'zip', sortMethod: 'string' },
                      { label: translations.tableHeadingCity(), sort: true, sortBy: 'city', sortMethod: 'string' },
                      { label: translations.tableHeadingCountry(), sort: true, sortBy: 'country.description', sortMethod: 'string' },
                      { label: translations.tableHeadingType(), sort: true, sortBy: 'role.description', sortMethod: 'string' },
                    ]}
                    tableData={data?.map(sitesTableRowData)}
                    rowData={data}
                    actionString={ACTION_CONST.API_GET_ADDRESSES}
                    getSortedRowsBack={(sortedRows: any) => setData(sortedRows?.slice())}
                />
            </article>
        </>
    );
};

