import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '../../../../../../core/utility/themeContext';
import { useTranslations } from '../../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../../constants/transitions/uiTranslations';
import { useSelectedMainContract, useSelectNumberOrder } from '../../../../../redux/store';
import { apiCallAction } from '../../../../../redux/actions/apiCall.action';
import { CpxModal } from '../../../../../../core/components/modal.component';
import { ACTION_CONST } from '../../../../../constants/action.constants';
import { PHONE_COUNTRY_CODES } from "../../../../../constants/configs/config.constants";
import { AddCallNumberRequest, Address, PortalContractData, ZipAreaCode } from 'compax-api';
import { CpxInputWithLabel } from "../../../../../../core/components/inputWithLabel.component";
import { CpxDropdownWithLabel } from "../../../../../../core/components/dropdown.component";
import { SelectOption } from "../../../../../constants/types/types.constants";
import { currency } from "../../../../../../core/utils";
import cs from 'classnames';
import './orderCallNumberPageTwo.scss';
import { deleteOCNStepData, saveOCNStepData } from "../../../../../redux/actions/numberOrder.action";
import { NfoActivationDate } from "../../../../../components/partner/newCustomer/configureProduct/activationDate/activationDate.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoOrderCallNumberPageTwo = ({
                                            onConfirm,
                                            backToPreviousPage,
                                            cancelModal,
                                            customerId
                                          }: any) => {
  const internalClassName = 'orderCallNumberPageTwo';
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.orderCallNumberModal);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const numberOrder = useSelectNumberOrder();
  const DEFAULT_QUANTITY = 1;
  const NO_OPTION_SELECTED: number = -1;
  const DEFAULT_SELECT_OPTION: SelectOption = {
    id: NO_OPTION_SELECTED,
    name: translationsCommon.defaultSelect(),
  }
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedLegalEntity, setSelectedLegalEntity] = useState(
    numberOrder?.stepData?.selectedLegalEntity || DEFAULT_SELECT_OPTION);
  const [selectableAddresses, setSelectableAddresses] = useState([DEFAULT_SELECT_OPTION]);
  const [selectedAddress, setSelectedAddress] = useState(
    numberOrder?.stepData?.selectedAddress || DEFAULT_SELECT_OPTION);
  const [selectableAreaCodes, setSelectableAreaCodes] = useState([DEFAULT_SELECT_OPTION]);
  const [selectedAreaCode, setSelectedAreaCode] = useState(
    numberOrder?.stepData?.selectedAreaCode || DEFAULT_SELECT_OPTION);
  const [areaCodeInput, setAreaCodeInput] = useState(numberOrder?.stepData?.areaCodeInput || '');
  const [selectableProducts, setSelectableProducts] = useState([DEFAULT_SELECT_OPTION]);
  const [selectedProduct, setSelectedProduct] = useState(DEFAULT_SELECT_OPTION)
  const selectedMainContract = useSelectedMainContract() as PortalContractData;
  const [activationDate, setActivationDate] = useState();

  const legalEntityOptions: Array<SelectOption> = numberOrder.customerLegalEntities?.map((legalEntity, index) => {
    return {
      id: index,
      name: `${legalEntity.countryCodePhone} - ${legalEntity.country?.description}`,
      data: legalEntity,
    };
  });

  const product = selectedProduct?.data?.product;

  /**
   * Get Legal Entities and corresponding data via API
   */
  useEffect(() => {
    dispatch(
      apiCallAction(ACTION_CONST.API_OCN_GET_CUSTOMER_LEGAL_ENTITIES, { contractId: selectedMainContract?.contractId, selectedPartnerCustomerId: customerId})
    );
  }, [dispatch]);

  /**
   * Prepare order after a legal entity got selected
   */
  useEffect(() => {
    if (selectedLegalEntity?.id !== NO_OPTION_SELECTED) {
      dispatch(
        apiCallAction(ACTION_CONST.API_OCN_GET_PREPARE_ORDER, {
          ...selectedLegalEntity.data.salesOrganisation,
          selectedPartnerCustomerId: customerId,
          telePhoneLineContractId: selectedLegalEntity.data.telephoneLineContractId,
          telePhoneLineServiceId: selectedLegalEntity.data.telephoneLineServiceId,

        })
      );
    }
  }, [selectedLegalEntity]);

  /**
   * Preparing data for selectable addresses
   */
  useEffect(() => {
    if (typeof selectedLegalEntity.data !== "undefined") {
      let addressOptions: Array<SelectOption> = [];
      let possibleAddresses: Array<Address> = selectedLegalEntity.data.possibleAddresses;

      if (possibleAddresses.length > 0) {
        addressOptions = possibleAddresses.map(address => {
          return {
            id: address.id!,
            name: `${address.street}${address.houseNumber !== undefined ? ' ' + address.houseNumber: ''}${address.houseNumberSuffix || ""}, ${address.zip} ${address.city} (${address.role?.description})`,
            data: address,
          };
        });
        setSelectableAddresses(addressOptions);
      } else {
        setSelectableAddresses([DEFAULT_SELECT_OPTION]);
      }
    }
  }, [selectedLegalEntity]);

  /**
   * Preparing data for selectable area codes
   */
  useEffect(() => {
    let address: Address | undefined;
    let areaCodes: Array<ZipAreaCode> | undefined = [];
    let areaCodeOptions: Array<SelectOption> = [];

    if (typeof selectedLegalEntity.data !== "undefined") {
      let possibleAddresses: Array<Address> = selectedLegalEntity.data.possibleAddresses;
      if (possibleAddresses.length > 0) {
        address = possibleAddresses.find(pa => pa.id === selectedAddress.id);
      }
      if (typeof address !== "undefined") {
        areaCodes = address.areaCode;
      }
      if (typeof areaCodes !== "undefined" && areaCodes.length > 0) {
        areaCodeOptions = areaCodes.map((areaCode, index) => {
          return {
            id: index,
            name: `${areaCode.areaCode} (${areaCode.zip} ${areaCode.city})`,
            data: areaCode.areaCode,
          };
        });
        setSelectableAreaCodes(areaCodeOptions);
      } else {
        setSelectableAreaCodes([DEFAULT_SELECT_OPTION]);
      }
    }
  }, [selectedAddress]);

  /**
   * Prepare Products for selection
   */
  useEffect(() => {
    if (numberOrder.possibleCallNumberServices.length > 0) {
      let productOptions: Array<SelectOption> = numberOrder.possibleCallNumberServices.map((service, index) => {
        let priceString = "";
        if (typeof service.product.charges !== "undefined"
          && service.product.charges.length > 0
          && typeof service.product.charges[0].amountNet !== "undefined"
          && service.product.charges[0].amountNet > 0) {
          priceString = `(${currency(service.product.charges[0].amountNet, service.product.charges[0]?.currency?.description)}*)`;
        }
        return {
          id: index,
          name: `${service.product.displayValue!} ${priceString}`,
          data: service,
        }
      });
      setSelectableProducts(productOptions);
    } else {
      setSelectableProducts([DEFAULT_SELECT_OPTION]);
    }
  }, [numberOrder.possibleCallNumberServices]);


  /* onChange Event Handlers */
  const handleLegalEntitySelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selectedId: number = +e.target.value;
    let selectedLegalEntityOption = legalEntityOptions.find(le => le.id === selectedId) || DEFAULT_SELECT_OPTION;
    setSelectedLegalEntity(selectedLegalEntityOption);
    setSelectedAddress(DEFAULT_SELECT_OPTION);
    setSelectedAreaCode(DEFAULT_SELECT_OPTION);
    setAreaCodeInput('');
    setSelectedProduct(DEFAULT_SELECT_OPTION);
    setErrorMsg('');
  }

  const handleAddressSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selectedId: number = +e.target.value;
    let selectedAddressOption = selectableAddresses.find(address => address.id === selectedId) || DEFAULT_SELECT_OPTION;
    setSelectedAddress(selectedAddressOption);
    setSelectedAreaCode(DEFAULT_SELECT_OPTION);
    setAreaCodeInput('');
    setSelectedProduct(DEFAULT_SELECT_OPTION);
    setErrorMsg('');
  }

  const handleAreaCodeSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selectedId: number = +e.target.value;
    let selectedAreaCodeOption = selectableAreaCodes.find(areaCode => areaCode.id === selectedId) || DEFAULT_SELECT_OPTION;
    setSelectedAreaCode(selectedAreaCodeOption);
    setSelectedProduct(DEFAULT_SELECT_OPTION);
    setErrorMsg('');
  }

  const handleAreaCodeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAreaCodeInput(e.target.value);
    setErrorMsg('');
  }

  const handleProductSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selectedId: number = +e.target.value;
    let selectedProductOption = selectableProducts.find(product => product.id === selectedId) || DEFAULT_SELECT_OPTION;
    setSelectedProduct(selectedProductOption);
    setErrorMsg('');
  }

  const isValidForm = (): boolean => {
    setErrorMsg('');
    if (selectedLegalEntity.id === NO_OPTION_SELECTED) {
      setErrorMsg(translations.errorLegalEntity());
      return false;
    }
    if (selectedAddress.id === NO_OPTION_SELECTED) {
      setErrorMsg(translations.errorAddress());
      return false;
    }
    // Check Area Code Input if an entity other than germany got selected
    if (typeof selectedLegalEntity.data !== "undefined" && selectedLegalEntity.data.countryCodePhone !== PHONE_COUNTRY_CODES.DE) {
      if (areaCodeInput.length < 1) {
        setErrorMsg(translations.errorAreaCodeLength());
        return false;
      } else {
        if (areaCodeInput.match(/\d+/g) === null) {
          setErrorMsg(translations.errorAreaCodeNumber());
          return false;
        } else if (!areaCodeInput.match(/^[0-9]+$/)) {
          setErrorMsg(translations.errorAreaCodeOnlyNumber());
          return false;
        }
      }
    } else if (selectedAreaCode.id === NO_OPTION_SELECTED) {
      setErrorMsg(translations.errorAreaCode());
      return false;
    }
    if (selectedProduct.id === NO_OPTION_SELECTED) {
      setErrorMsg(translations.errorProduct());
      return false;
    }
    return true;
  }

  const confirmForm = () => {

    let basket: AddCallNumberRequest;
    /**
     * Prepare basket if data is gathered
     */
    if (selectedProduct.id !== NO_OPTION_SELECTED && (selectedAreaCode.id !== NO_OPTION_SELECTED || areaCodeInput.length > 0)) {
      let areaCode = areaCodeInput.length > 0 ? areaCodeInput : selectedAreaCode.data;
      basket = {
        basketId: numberOrder.basket.id,
        areaCode: areaCode,
        address: selectedAddress?.data,
        callNumberServiceId: selectedProduct.data.product.id,
        quantity: DEFAULT_QUANTITY,
        clientId: selectedLegalEntity.data.id,
        customerId: numberOrder.basket.customer,
        salesOrganisation: selectedLegalEntity.data.salesOrganisation,
        theParentServiceUuid: selectedProduct.data.parentUuid,
      }
    }

    // @ts-ignore
    if (isValidForm() && typeof basket !== "undefined" && typeof basket?.callNumberServiceId !== "undefined") {
      dispatch(
        apiCallAction(ACTION_CONST.API_OCN_PUT_BASKET_ADD_OPTION, {...basket, selectedPartnerCustomerId: customerId }, true)
      )
        .then(() => {
          /**
           * Add requested start date
           */
          dispatch(apiCallAction(ACTION_CONST.API_BASKET_PUT_ACTIVATION_DATE,
              {uuid:  numberOrder.basket?.entries[0].uuid, basketId: numberOrder.basket?.id, activationDate}
          ))
        });

      dispatch(saveOCNStepData(
        {
          selectedLegalEntity,
          selectedAddress,
          selectedProduct,
          selectedAreaCode,
          areaCodeInput
        }))
      onConfirm(selectedLegalEntity.name, selectedAddress.name, product);
    }
  }


  const backToProductTypeSelection = () => {
    backToPreviousPage();
  };

  const cancelModalHandler = () => {
    dispatch(deleteOCNStepData())
    cancelModal(false);
  }

  return (
    <>
      <CpxModal
        onCancel={() => cancelModalHandler()}
        onBack={() => backToProductTypeSelection()}
        onConfirm={() => confirmForm()}
        confirmText={translations.next()}
      >
        <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {translations.titlePage2()}
        </h3>
        <p className={`${internalClassName}-modalSubTitle ${internalClassName}-modalSubTitle-le--${theme}`}>
          {translations.subTextPage2()}
        </p>
        {errorMsg &&
          <p className={`${internalClassName}-alert`}>
            {errorMsg}
          </p>
        }
        <CpxDropdownWithLabel
          id={`${internalClassName}-connection`}
          defaultOption={DEFAULT_SELECT_OPTION}
          options={legalEntityOptions}
          value={selectedLegalEntity.id}
          onChange={handleLegalEntitySelection}
        >
          {translations.telephoneConnection()}
        </CpxDropdownWithLabel>
        <CpxDropdownWithLabel
          id={`${internalClassName}-location`}
          defaultOption={DEFAULT_SELECT_OPTION}
          options={selectableAddresses}
          value={selectedAddress.id}
          disabled={selectedLegalEntity.id === NO_OPTION_SELECTED}
          onChange={handleAddressSelection}
        >
          {translations.location()}
        </CpxDropdownWithLabel>
        {selectedLegalEntity.data &&
        selectedLegalEntity.data.countryCodePhone === PHONE_COUNTRY_CODES.DE ?
          <CpxDropdownWithLabel
            id={`${internalClassName}-area-code`}
            defaultOption={DEFAULT_SELECT_OPTION}
            options={selectableAreaCodes}
            value={selectedAreaCode.id}
            disabled={selectedAddress.id === NO_OPTION_SELECTED}
            onChange={handleAreaCodeSelection}
          >
            {translations.areaCode()}
          </CpxDropdownWithLabel>
          :
          <CpxInputWithLabel
            id={`${internalClassName}-area-code-input`}
            value={areaCodeInput}
            onChange={handleAreaCodeInput}
            disabled={selectedAddress.id === NO_OPTION_SELECTED}
            maxLength={5}
          >
            {translations.areaCode()}
          </CpxInputWithLabel>
        }
        <hr className={cs(`${internalClassName}-hr`, `${internalClassName}-hr-le--${theme}`)}/>
        <h4 className={cs(`${internalClassName}-section-title`, `${internalClassName}-section-title-le--${theme}`)}>{translations.callnumber()}</h4>
        <CpxDropdownWithLabel
          id={`${internalClassName}-callnumberblocks`}
          defaultOption={DEFAULT_SELECT_OPTION}
          options={selectableProducts}
          value={selectedProduct.id}
          disabled={selectedAreaCode.id === NO_OPTION_SELECTED && areaCodeInput.length < 1}
          onChange={handleProductSelection}
        >
          {translations.callNumberBlock()}
        </CpxDropdownWithLabel>
        <hr className={cs(`${internalClassName}-hr`, `${internalClassName}-hr-le--${theme}`)}/>
        <NfoActivationDate setActivationDate={setActivationDate} />
      </CpxModal>
    </>
  );
};
